import { OrdersContentHeader } from '@features/orders/components';
import { OrdersCard } from '@features/orders/components/OrdersCard';
import { AppLayout } from '@features/ui/components/layout/AppLayout';
import { NextPageWithLayout } from '@features/ui/types/NextPageWithLayout';
import staticProps from '@services/staticProps';
import { ReactElement } from 'react';

const Home: NextPageWithLayout = () => (
  <>
    <OrdersContentHeader />
    <OrdersCard />
  </>
);

Home.getLayout = function getLayout(page: ReactElement) {
  return <AppLayout>{page}</AppLayout>;
};

export default Home;

export const getStaticProps = staticProps;
