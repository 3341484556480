import { Spinner } from '@features/ui/components/Spinner';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

export const LoadingOverlay = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'z-loadingOverlay absolute top-0 left-0 flex h-screen w-screen',
        'bg-brand-black flex-col items-center justify-center gap-y-6 bg-opacity-30',
      )}
    >
      <Spinner className="text-6xl" />
      <h1 className="text-2xl font-semibold">{t('component.button.loading')}...</h1>
    </div>
  );
};
