import { AuthUserConnectedImpersonated, AuthUserConnectedType } from '@ch-apptitude-icc/lablink/shared/entities';
import { AppHeader, Layout, LayoutProps } from '@features/ui/components';
import { useApi } from '@services/api';
import { getRoute } from '@services/routes';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { inter } from './fonts/inter';
import { LoadingOverlay } from './LoadingOverlay';

export type AppLayoutProps = Pick<LayoutProps, 'children'>;

export const AppLayout = ({ children }: AppLayoutProps): JSX.Element => {
  const { authApi } = useApi();
  const { t } = useTranslation();
  const router = useRouter();

  const { data, isLoading } = authApi.useGetMe(
    {
      onError: (err, next) => {
        if (!err.response || (err.response.status !== 401 && err.response.status !== 403)) {
          void router.push(getRoute.down());
        } else {
          authApi.apiCallHelper.onError(err, next);
        }
      },
    },
    auth => auth,
  );

  if (isLoading || !data || data.type === AuthUserConnectedType.REGULAR) {
    return <Layout header={<AppHeader />}>{isLoading ? <LoadingOverlay /> : children}</Layout>;
  }

  // Height of the banner and the offset of the header
  const clsNames = {
    // No string concatenation or tailwind will not find theses mandatory classes
    height: 'h-24',
    margin: 'mt-24',
    top: 'top-24',
  } as const;

  return (
    <>
      <div
        className={classNames(
          'fixed top-0 z-50 w-full',
          'flex flex-col justify-center text-center',
          'bg-amber-500 text-white',
          clsNames.height,
          inter.className,
        )}
      >
        <p>
          <Trans
            i18nKey="component.auth-banner.connected-as"
            t={t}
            components={{ m: <AuthBannerImpersonated auth={data} /> }}
          />
        </p>
        <p className="font-bold">{t('component.auth-banner.action-is-real')}</p>
      </div>

      <Layout className={clsNames.margin} header={<AppHeader />} headerTop={clsNames.top}>
        {children}
      </Layout>
    </>
  );
};

function AuthBannerImpersonated({ auth }: { auth: AuthUserConnectedImpersonated }) {
  return <span className="italic">{auth.impersonator.name}</span>;
}
