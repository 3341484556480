import { OrderStatus } from '@ch-apptitude-icc/lablink/shared/entities';
import { statusToChipsVariant } from '@features/orders/utils/';
import { Button } from '@features/ui/components';
import { useModal } from '@features/ui/modal';
import { useTranslation } from 'next-i18next';

type OrdersTableHeaderProps = {
  onClick: (status: OrderStatus) => void;
  selected: OrderStatus[];
};

export const OrdersTableHeader = ({ onClick, selected }: OrdersTableHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  return (
    <div className="relative flex flex-col items-start md:flex-row md:items-center">
      <span className="bloc pb-3 md:pr-6 md:pb-0">{t('pages.orders.filterBy')}</span>
      <div className="flex grow flex-wrap items-center gap-2">
        {statusToChipsVariant[OrderStatus.TO_ORDER](t, {
          onClick: () => onClick(OrderStatus.TO_ORDER),
          selected: selected.includes(OrderStatus.TO_ORDER),
        })}
        {statusToChipsVariant[OrderStatus.ORDERED](t, {
          onClick: () => onClick(OrderStatus.ORDERED),
          selected: selected.includes(OrderStatus.ORDERED),
        })}
        {statusToChipsVariant[OrderStatus.IN_PROGRESS](t, {
          onClick: () => onClick(OrderStatus.IN_PROGRESS),
          selected: selected.includes(OrderStatus.IN_PROGRESS),
        })}
        {statusToChipsVariant[OrderStatus.DONE](t, {
          onClick: () => onClick(OrderStatus.DONE),
          selected: selected.includes(OrderStatus.DONE),
        })}
      </div>
      <Button
        onClick={() => openModal('statusInfo')}
        variant="table"
        label={t('common.help')}
        leftIcon="messageQuestion"
        className="absolute right-0 top-0"
      />
    </div>
  );
};
