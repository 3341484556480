import { OrderStatus } from '@ch-apptitude-icc/lablink/shared/entities';
import { Card } from '@features/ui/components';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { OrdersTable } from './OrdersTable';
import { OrdersTableHeader } from './OrdersTableHeader';

export const OrdersCard = (): JSX.Element => {
  const { t } = useTranslation('common');

  const [filterSelection, setFilterSelection] = useState<OrderStatus[]>([]);
  const addFilterSelection = (status: OrderStatus) => {
    if (filterSelection.includes(status)) {
      setFilterSelection(filterSelection.filter(s => s !== status));
    } else {
      setFilterSelection([...filterSelection, status]);
    }
  };

  return (
    <Card header={<OrdersTableHeader selected={filterSelection} onClick={addFilterSelection} />}>
      <OrdersTable
        statusSelection={filterSelection}
        noResultsItems={[
          t('pages.orders.table.noResultsItems.firstAndLastName'),
          t('pages.orders.table.noResultsItems.yourFileRef'),
        ]}
      />
    </Card>
  );
};
